<template>
<v-container>
  <!-- Page title -->
 <v-row>
   <h2>Search VIN data</h2>
 </v-row>

  <!-- Form -->
  <v-row>
    <!-- Form inputs -->
    <v-col cols="12">
      <!-- VIN text field -->
      <v-text-field label="VIN"
                    placeholder="VIN"
                    v-model="vin"
                    :rules="[rules.length, rules.vin]"
                    counter
                    maxlength="17"
                    required
                    outlined
                    append-outer-icon="mdi-send"
                    @click:append-outer="searchVINData"
      >
      </v-text-field>
    </v-col>

    <!-- Alerting for error case -->
    <v-col cols="12">
      <v-alert border="right" colored-border type="error" elevation="2" v-if="isError" width="100%">
        {{errorMessage}}
      </v-alert>
    </v-col>

  </v-row>

  <!-- Result display -->
  <v-row>
    <v-col cols="12" v-if="searchResult.length > 0">
      <v-card>

        <!-- Title -->
        <v-card-title>Result for {{vin}}</v-card-title>

        <!-- Card content -->
        <v-card-text>

          <!-- Main data table -->
          <v-data-table
              :headers="resultHeaders"
              :items="searchResult"
              :single-expand="true"
              :expanded.sync="resultExpanded"
              item-key="infoName"
              show-expand
              class="elevation-1"
          >

            <!-- Overwrite score to add color -->
            <template v-slot:item.infoRecommendedData.score="{ item }">
              <v-chip :color="getColor(item.infoRecommendedData.score)" dark>
                {{ Math.trunc(item.infoRecommendedData.score * 100) / 100 }}
              </v-chip>
            </template>

            <!-- Overwrite local reliability to have only 2 digits -->
            <template v-slot:item.infoRecommendedData.localReliability="{ item }">
                {{ Math.trunc(item.infoRecommendedData.localReliability * 100) / 100 }}
            </template>

            <!-- Overwrite global reliability to have only 2 digits -->
            <template v-slot:item.infoRecommendedData.globalReliability="{ item }">
              {{ Math.trunc(item.infoRecommendedData.globalReliability * 100) / 100 }}
            </template>

            <!-- Manage expand items -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" v-if="item.infoAdditionalData !== null" class="pt-2 pb-2">
                <v-data-table
                    :headers="moreHeaders"
                    :items="item.infoAdditionalData"
                    :item-key="'more_'+  item.infoAdditionalData.nameInApDB + '_'  + item.infoAdditionalData.value"
                >

                  <!-- Overwrite score to add color -->
                  <template v-slot:item.score="{ item }">
                    <v-chip :color="getColor(item.score)" dark>
                      {{ item.score }}
                    </v-chip>
                  </template>

                  <!-- Overwrite local reliability to have only 2 digits -->
                  <template v-slot:item.localReliability="{ item }">
                    {{ Math.trunc(item.localReliability * 100) / 100 }}
                  </template>

                  <!-- Overwrite global reliability to have only 2 digits -->
                  <template v-slot:item.globalReliability="{ item }">
                    {{ Math.trunc(item.globalReliability * 100) / 100 }}
                  </template>

                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
</v-container>
</template>

<script>
import api from '../../api'

export default {
  name: 'Core',
  data () {
    return {
      vin: '',
      rules: {
        length: value => value.length === 17 || '17 characters',
        vin: value => {
          const pattern = /^[^\Wioq]{17,19}$/
          return pattern.test(value) || 'Invalid vin.'
        }
      },
      searchResult: [],
      resultExpanded: [],
      resultHeaders: [
        { text: 'Score', value: 'infoRecommendedData.score' },
        { text: 'Name in AP DB', value: 'infoRecommendedData.nameInApDB' },
        { text: 'Value', value: 'infoRecommendedData.value' },
        { text: 'Nb occ.', value: 'infoRecommendedData.numberOfOccurrences' },
        { text: 'Local rel.', value: 'infoRecommendedData.localReliability' },
        { text: 'Global rel.', value: 'infoRecommendedData.globalReliability' },
        { text: 'Priority', value: 'infoRecommendedData.priority' }
      ],
      moreHeaders: [
        { text: 'Score', value: 'score' },
        { text: 'Name in AP DB', value: 'nameInApDB' },
        { text: 'Value', value: 'value' },
        { text: 'Nb occ.', value: 'numberOfOccurrences' },
        { text: 'Local rel.', value: 'localReliability' },
        { text: 'Global rel.', value: 'globalReliability' },
        { text: 'Priority', value: 'priority' }
      ],
      isError: false,
      errorMessage: 'An error occurred during VIN search. Please check your data.'
    }
  },
  methods: {
    searchVINData () {
      console.log('Start VIN searching data...')

      const self = this
      self.searchResult = []

      api.post('/vinrequest/create', { kind: 'vinrequest', vin: this.vin, existingVinCompletion: true })
        .then((response) => {
          console.log('End of search')
          console.log(response)
          if (response.data.entity !== null) {
            const jsonObjectAsMap = Object.entries(response.data.entity.response.vinInfos)

            for (let i = 0; i < jsonObjectAsMap.length; i++) {
              if (Array.isArray(jsonObjectAsMap[i][1])) {
                self.searchResult.push({
                  infoName: jsonObjectAsMap[i][0],
                  infoRecommendedData: jsonObjectAsMap[i][1][0],
                  infoAdditionalData: jsonObjectAsMap[i][1].length > 1 ? jsonObjectAsMap[i][1].slice(1, jsonObjectAsMap[i][1].length) : null
                })
              }
            }
          }
        }).catch(error => {
          console.log('An error occurred during search')
          console.log(error)

          self.isError = true

          if (error.response && error.response.status === 500) {
            self.errorMessage = 'A technical error occurred. Please contact ATeam.'
          }
        })
    },
    getColor (score) {
      if (score < 0.5) return 'red'
      else if (score >= 0.5 && score < 0.8) return 'orange'
      else return 'green'
    }
  }

}
</script>

<style scoped>

</style>
