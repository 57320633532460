var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('h2',[_vm._v("Search VIN data")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"VIN","placeholder":"VIN","rules":[_vm.rules.length, _vm.rules.vin],"counter":"","maxlength":"17","required":"","outlined":"","append-outer-icon":"mdi-send"},on:{"click:append-outer":_vm.searchVINData},model:{value:(_vm.vin),callback:function ($$v) {_vm.vin=$$v},expression:"vin"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.isError)?_c('v-alert',{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2","width":"100%"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)],1),_c('v-row',[(_vm.searchResult.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Result for "+_vm._s(_vm.vin))]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.resultHeaders,"items":_vm.searchResult,"single-expand":true,"expanded":_vm.resultExpanded,"item-key":"infoName","show-expand":""},on:{"update:expanded":function($event){_vm.resultExpanded=$event}},scopedSlots:_vm._u([{key:"item.infoRecommendedData.score",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.infoRecommendedData.score),"dark":""}},[_vm._v(" "+_vm._s(Math.trunc(item.infoRecommendedData.score * 100) / 100)+" ")])]}},{key:"item.infoRecommendedData.localReliability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.trunc(item.infoRecommendedData.localReliability * 100) / 100)+" ")]}},{key:"item.infoRecommendedData.globalReliability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.trunc(item.infoRecommendedData.globalReliability * 100) / 100)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.infoAdditionalData !== null)?_c('td',{staticClass:"pt-2 pb-2",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.moreHeaders,"items":item.infoAdditionalData,"item-key":'more_'+  item.infoAdditionalData.nameInApDB + '_'  + item.infoAdditionalData.value},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.score),"dark":""}},[_vm._v(" "+_vm._s(item.score)+" ")])]}},{key:"item.localReliability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.trunc(item.localReliability * 100) / 100)+" ")]}},{key:"item.globalReliability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.trunc(item.globalReliability * 100) / 100)+" ")]}}],null,true)})],1):_vm._e()]}}],null,false,2131944279)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }